#menu {
  position: absolute;
  right: 0px;
  float: right;
  width: 50px;
  z-index: 999999;
  background: #ffffff; }

#menu li {
  float: right !important;
  list-style: none;
  width: 50px;
  height: 40px;
  text-align: center;
  position: relative; }

#menu li div {
  opacity: 0;
  font-size: 10px;
  position: absolute;
  right: 50px;
  text-align: right;
  top: 5px;
  min-width: 95px;
  vertical-align: unset;
  padding: 5px 10px;
  transition: all 500ms ease-out; }

#menu li a {
  color: #000000; }

#menu li:hover div {
  display: block;
  opacity: 1;
  font-size: 10px;
  position: absolute;
  right: 50px;
  text-align: right;
  top: 5px;
  min-width: 95px;
  vertical-align: unset;
  padding: 5px 10px; }

.deco {
  font-size: 90px;
  position: absolute;
  bottom: 10%;
  right: 5%;
  opacity: 0.1;
  font-weight: 900;
  text-transform: uppercase;
  z-index: -1;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  position: fixed; }

@media (max-width: 600px) {
  .deco {
    font-size: 30px; } }

*,
a {
  outline: 0; }

.scroll-tip {
  bottom: 280px;
  position: absolute;
  text-align: center;
  width: 100%; }

.scroll-tip a {
  display: block;
  margin: 0 auto;
  width: 200px;
  text-decoration: none; }

.scroll-tip .icon {
  display: block;
  margin: 0 auto;
  padding-bottom: 30px; }

.icon.scroll {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAuCAYAAAA2oQl1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3RjFBM0E3OTVEMzYxMUU0QUJGRkYxQjQ4OTM0OUUxQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3RjFBM0E3QTVEMzYxMUU0QUJGRkYxQjQ4OTM0OUUxQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjdGMUEzQTc3NUQzNjExRTRBQkZGRjFCNDg5MzQ5RTFCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjdGMUEzQTc4NUQzNjExRTRBQkZGRjFCNDg5MzQ5RTFCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Lk1l6gAAAehJREFUeNrsmM9KAlEUh2cG0iB8AE3QSKhVbSIhxFq6U9pFy9q51p2FPUubrKTeQQSlNkF7A9Nat1EJpt+NM3A4OH+dImIOfDKOd843c2fu9Z7RTdPUXCIJyqAA9kAKzMAreAQdcA/GjlmUyIZd0DO9R4+OmZvPTtIwg0fDi2gbdMWBU9ACx2ANxEECbNK+FrXh0aVctiIpaYOcQ/da5KitlM0VnbFGn6DuQSCp07FWnEtRUZxNEAmX8ShyEX+6bheQWNyIp/FblBE3Ph2CKC0ekIyBoVRhw+oaDLXFY0i5rKgYNOKtuNPCC56roC5zwC/RpUtKYESUXNryWzLQ8TGBMU7mZTB1OMsRzX0azW0ph7YxlmumRHxW1V26Q87Antsb2i9FJPp/Iv53/faTolPwTuuFEz8H+h1HfiMaR5EoEkWivyL6YN8TIeaOse2ZIab+ZIginmusRE9sx1aIoh22/WBQDWpFOUQRX2p31Ioyy1aUE7AawiI/RbmsyFo/9NnOVgiiFsvX5/XRviieagtIaiLXgSwtm6K0rAaQVEVp2fRaLKsuWPcgUG2uvBbLTuW/SnJEpcgSWAEb4BBcBin/53Wj37jw8+ZEkQ/wiiZvl0/38NIpRYNPvnRSdeozDfg2eHFK8iXAAKnVAzSxv5tPAAAAAElFTkSuQmCC");
  height: 46px;
  width: 26px; }

.icon {
  background-repeat: no-repeat;
  opacity: 0.5; }

.scroll-tip .label {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none; }

#copyright {
  text-align: left;
  z-index: 9999999; }

.mini {
  width: 20px;
  vertical-align: middle;
  margin-right: 5px; }

.splitter li,
.splitter ul,
.splitter ul li {
  display: inline-block;
  list-style: none;
  padding: 5px; }

.demo {
  clear: both; }

#list li {
  float: left;
  list-style: none;
  width: 150px; }

#filterOptions img {
  width: 20px;
  vertical-align: middle; }

/*- -*/
/*- OUR DATA HOLDER -*/
ul.ourHolder {
  width: 100% !important;
  padding: 0px; }

ul.ourHolder li.itemC {
  width: 160px;
  height: 250px;
  float: left;
  text-align: center;
  overflow: hidden;
  position: relative;
  margin: 10px;
  background: #efefef;
  list-style: none; }

ul.ourHolder li.itemC h3 {
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px; }

/*- -*/
*,
*:after,
*:before {
  box-sizing: border-box; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

.container {
  width: 100%;
  position: relative; }

.container > header h1 span {
  display: block;
  font-size: 20px;
  font-weight: 300; }

.main > footer {
  text-align: center;
  padding: 30px 20px; }

.main > footer p {
  display: block;
  padding: 10px; }

.grid {
  list-style: none;
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
  width: 100%; }

.grid li {
  display: inline-block;
  margin: 10px 5px 0 5px;
  vertical-align: top;
  height: 300px;
  border-radius: 3px;
  position: relative;
  width: 250px;
  height: 300px;
  overflow: hidden; }

.media {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 95%;
  z-index: 1; }

.comment {
  margin-top: 12px;
  color: #666;
  font-size: 12px;
  max-height: 88px;
  overflow: hidden;
  bottom: 0px;
  position: absolute;
  background: #999;
  z-index: 99;
  width: 100%;
  padding: 8px;
  background: #e2e2e2;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZTJlMmUyIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjZGJkYmRiIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNTElIiBzdG9wLWNvbG9yPSIjZDFkMWQxIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZlZmVmZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(45deg, #e2e2e2 0%, #dbdbdb 50%, #d1d1d1 51%, #fefefe 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=1);
  /* IE6-8 fallback on horizontal gradient */ }

.comment > a {
  color: #000000;
  text-decoration: none;
  font-family: "Lato";
  text-transform: capitalize; }

.comment > a:hover {
  color: #cc3333; }

.content > p {
  display: inline-block;
  color: #9BA6B0;
  font-weight: 500; }

.content > .avatar {
  background-size: 60px auto;
  border-radius: 50%;
  display: inline-block;
  height: 60px;
  margin-right: 0px;
  vertical-align: middle;
  width: 60px; }

/* Video.js play button */
.vjs-default-skin .vjs-big-play-button:before {
  color: #fff !important;
  text-shadow: none !important;
  line-height: 0 !important; }

.vjs-default-skin .vjs-big-play-button {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  left: 50% !important;
  top: 50% !important;
  font-size: 8em !important;
  margin-left: -30px !important;
  height: 0 !important;
  width: 0 !important; }

.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin .vjs-big-play-button:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  outline: 0 none !important;
  transition: none !important; }

.regions li {
  float: left;
  padding: 0 0 20px;
  width: 33%;
  list-style: none; }

.regions li .iconflag {
  margin-right: 20px; }

.regions li a {
  color: #000000;
  text-decoration: none; }

.iconflag {
  /*background: url("flags.jpg") no-repeat scroll 0 0 transparent;*/
  display: inline-block;
  height: 28px;
  vertical-align: middle;
  width: 40px; }

.iconflag.ad {
  background-position: 0 -168px; }

.iconflag.ar {
  background-position: 0 -308px; }

.iconflag.at {
  background-position: 0 -420px; }

.iconflag.au {
  background-position: 0 -392px; }

.iconflag.be-fr,
.iconflag.be-nl,
.iconflag.be {
  background-position: 0 -616px; }

.iconflag.br {
  background-position: 0 -840px; }

.iconflag.bg {
  background-position: 0 -951px; }

.iconflag.bo {
  background-position: 0 -756px; }

.iconflag.ca-en,
.iconflag.ca-fr,
.iconflag.ca {
  background-position: 0 -1092px; }

.iconflag.ch-de,
.iconflag.ch-fr {
  background-position: 0 -6117px; }

.iconflag.cl {
  background-position: 0 -1232px; }

.iconflag.co {
  background-position: 0 -1344px; }

.iconflag.cr {
  background-position: 0 -1456px; }

.iconflag.cy {
  background-position: 0 -1568px; }

.iconflag.cz {
  background-position: 0 -1596px; }

.iconflag.de {
  background-position: 0 -2268px; }

.iconflag.dk {
  background-position: 0 -1652px; }

.iconflag.do {
  background-position: 0 -1736px; }

.iconflag.ec {
  background-position: 0 -1792px; }

.iconflag.ee {
  background-position: 0 -1960px; }

.iconflag.es {
  background-position: 0 -5949px; }

.iconflag.fi {
  background-position: 0 -2128px; }

.iconflag.fr {
  background-position: 0 -2156px; }

.iconflag.gr {
  background-position: 0 -2381px; }

.iconflag.gt {
  background-position: 0 -2493px; }

.iconflag.hk-en,
.iconflag.hk-zh,
.iconflag.hk {
  background-position: 0 -2689px; }

.iconflag.hn {
  background-position: 0 -2661px; }

.iconflag.hu {
  background-position: 0 -2717px; }

.iconflag.ie {
  background-position: 0 -2885px; }

.iconflag.il {
  background-position: 0 -2941px; }

.iconflag.ir {
  background-position: 0 -2830px; }

.iconflag.id {
  background-position: 0 -2801px; }

.iconflag.is {
  background-position: 0 -2745px; }

.iconflag.it {
  background-position: 0 -2969px; }

.iconflag.li {
  background-position: 0 -3445px; }

.iconflag.lt {
  background-position: 0 -3473px; }

.iconflag.lu-de,
.iconflag.lu-fr {
  background-position: 0 -3500px; }

.iconflag.lv {
  background-position: 0 -3305px; }

.iconflag.mc {
  background-position: 0 -3977px; }

.iconflag.mt {
  background-position: 0 -3725px; }

.iconflag.mx {
  background-position: 0 -3893px; }

.iconflag.my-en {
  background-position: 0 -3641px; }

.iconflag.my-ms {
  background-position: 0 -3641px; }

.iconflag.no {
  background-position: 0 -4661px; }

.iconflag.ni {
  background-position: 0 -4437px; }

.iconflag.nl {
  background-position: 0 -4353px; }

.iconflag.nz {
  background-position: 0 -4409px; }

.iconflag.pa {
  background-position: 0 -4829px; }

.iconflag.pe {
  background-position: 0 -4913px; }

.iconflag.ph {
  background-position: 0 -4941px; }

.iconflag.pl {
  background-position: 0 -4997px; }

.iconflag.pt {
  background-position: 0 -5025px; }

.iconflag.py {
  background-position: 0 -4885px; }

.iconflag.ro {
  background-position: 0 -1204px; }

.iconflag.se {
  background-position: 0 -6089px; }

.iconflag.sg-en {
  background-position: 0 -5641px; }

.iconflag.sk {
  background-position: 0 -5669px; }

.iconflag.sv {
  background-position: 0 -1848px; }

.iconflag.gb,
.iconflag.tr {
  background-position: 0 -6397px; }

.iconflag.tw {
  background-position: 0 -6173px; }

.iconflag.uk {
  background-position: 0 -6621px; }

.iconflag.us {
  background-position: 0 -6677px; }

.iconflag.uy {
  background-position: 0 -6705px; }

.iconflag.za {
  background-position: 0 -5809px; }
