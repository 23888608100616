html {
  font-family: 'Lato', Arial, sans-serif !important; }

body {
  background: #ffffff; }

.flaglg {
  padding: 5px; }

.flagI {
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px; }

.country {
  border: 0px !important;
  transition: all 0.8s ease; }

.country:hover a {
  border: 0px !important;
  background: #efefef !important; }

.vjs-control-bar {
  display: none; }

.videoContainer {
  height: 100%;
  width: 100%;
  overflow: hidden; }

.videoContainer video {
  height: auto;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: auto;
  z-index: -1;
  background: #000000; }

.list {
  margin: -150px auto 0px;
  width: 100%;
  text-align: center; }

.itemCt {
  width: 170px;
  display: inline-block;
  margin: 25px;
  list-style: none !important;
  transition: all 0.3s linear;
  cursor: pointer; }

.itemTxt {
  float: left;
  margin-top: -70px;
  padding: 10px 30px;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  width: 170px;
  height: 70px;
  color: #CC3333;
  font-weight: bold;
  transition: all 1s linear; }

.imgdeco {
  border: 5px solid #fff; }

.imgdeco2 {
  border: 5px solid #333; }

.item {
  border: 5px solid #fff;
  float: left;
  overflow: hidden; }

.itemCt:hover {
  background: #FFF; }

.item img {
  width: 160px;
  transition: all 1s linear; }

#fullPage-nav {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateX(-375px);
          transform: translateX(-375px); }

#fullPage-nav.visible {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
  transition: all 2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s; }

.newstab-open #fullPage-nav {
  pointer-events: all;
  -webkit-transform: translateX(-375px);
          transform: translateX(-375px);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s; }

#fullPage-nav.left {
  position: fixed;
  top: 45%; }

#fullPage-nav li {
  height: auto;
  width: auto;
  list-style: none; }

#fullPage-nav li + li {
  margin-top: 8px; }

#fullPage-nav a {
  display: block;
  transition: all 0.3s linear; }

#fullPage-nav a span {
  background: none repeat scroll 0 0 #14292e !important;
  border: 10px solid #EFEFEF;
  display: block;
  height: 20px;
  left: 0;
  position: relative;
  top: 0;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  width: 20px;
  border-radius: 10px; }

@media only screen and (max-width: 567px) {
  .itemC {
    width: 250px !important; } }

@media only screen and (max-width: 767px) {
  #fullPage-nav a span {
    border-width: 5px;
    height: 10px;
    width: 10px; }
  .nomobile {
    display: none !important; } }

#fullPage-nav a:hover span {
  border: 8px solid white;
  box-shadow: 2px 2px 2px #999; }

@media only screen and (max-width: 767px) {
  #fullPage-nav a:hover span {
    border-width: 4px; } }

#fullPage-nav a.active span,
#fullPage-nav a.active:hover span {
  background: none repeat scroll 0 0 white !important;
  border: 8px solid #cc3333; }

@media only screen and (max-width: 767px) {
  #fullPage-nav a.active span,
  #fullPage-nav a.active:hover span {
    border-width: 4px; } }

.btn.btn-more.blue {
  background: none repeat scroll 0 0 #cc3333;
  margin-bottom: 10px;
  margin-right: 10px;
  color: white;
  padding: 10px;
  text-decoration: none; }

.flag {
  background-repeat: no-repeat;
  background-size: 25px auto;
  float: left;
  height: 35px;
  text-indent: 45px;
  width: 100%; }

.container {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.05); }

#copyright {
  text-align: left; }

#copyright p {
  color: #727272;
  font-size: 10px; }

#copyright {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
  bottom: 0;
  position: fixed;
  text-align: left;
  width: 100%;
  height: 25px; }

.socialRight {
  float: left;
  width: 30px;
  margin-left: 20px; }

.socialRight2 {
  font-weight: 900;
  margin-left: 20px;
  margin-right: 26px; }

.social a {
  color: #727272;
  text-decoration: none; }

.social a:hover {
  color: #fff; }

.container > header,
.codrops-top {
  color: #fff;
  font-family: 'Lato', Arial, sans-serif; }

.container > header {
  margin: 0 auto;
  padding: 12em 2em;
  padding-left: 370px;
  position: absolute;
  top: 0px; }

.container > header a {
  color: #566473;
  text-decoration: none;
  outline: none; }

.container > header a:hover {
  color: #4f7bab; }

.container > header h1 {
  font-size: 3.2em;
  line-height: 1.3;
  margin: 0;
  font-weight: 300; }

.container > header span {
  display: block;
  font-size: 55%;
  color: #74818e;
  padding: 0 0 0.6em 0.1em; }

/* To Navigation Style */
.codrops-top {
  background: #fff;
  background: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  width: 100%;
  font-size: 0.69em;
  line-height: 2.2; }

.codrops-top a {
  text-decoration: none;
  padding: 0 1em;
  letter-spacing: 0.1em;
  color: #888;
  display: inline-block; }

.codrops-top a:hover {
  background: rgba(255, 255, 255, 0.95);
  color: #333; }

.codrops-top span.right {
  float: right; }

.codrops-top span.right a {
  float: left;
  display: block; }

.codrops-icon:before {
  font-family: 'codropsicons';
  margin: 0 4px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.codrops-icon-drop:before {
  content: "\E001"; }

.codrops-icon-prev:before {
  content: "\E004"; }

/* Demo Buttons Style */
.codrops-demos {
  padding-top: 1em;
  font-size: 0.9em; }

.codrops-demos a {
  text-decoration: none;
  outline: none;
  display: inline-block;
  margin: 0.5em;
  padding: 0.7em 1.1em;
  border: 3px solid #b1aea6;
  color: #b1aea6;
  font-weight: 700; }

.codrops-demos a:hover,
.codrops-demos a.current-demo,
.codrops-demos a.current-demo:hover {
  border-color: #89867e;
  color: #89867e; }

@media screen and (max-width: 1025px) {
  .container > header {
    margin: 0 auto;
    padding: 120px 50px;
    font-size: 70%;
    text-align: right; } }

@media screen and (max-width: 740px) {
  .codrops-icon span {
    display: none; } }

.menuBtSous:hover .gn-menu-sous {
  opacity: 1;
  height: 200px; }

.gn-menu-sous {
  opacity: 0;
  height: 0px; }

/**
 * Tooltips!
 */
/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer; }

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none; }

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: ""; }

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2; }

/* Directions */
/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%; }

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: rgba(51, 51, 51, 0.9); }

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px; }

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  transform: translateY(-12px); }

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto; }

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: rgba(51, 51, 51, 0.9); }

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  transform: translateX(-12px); }

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%; }

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: rgba(51, 51, 51, 0.9); }

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  transform: translateY(12px); }

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%; }

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: rgba(51, 51, 51, 0.9); }

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  transform: translateX(12px); }

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px; }

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px; }

#bg-light {
  overflow: hidden !important; }

@-webkit-keyframes boxSpin {
  0% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px); }
  25% {
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px); }
  50% {
    -webkit-transform: translate(10px, -10px);
            transform: translate(10px, -10px); }
  75% {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px); }
  100% {
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px); } }

@keyframes boxSpin {
  0% {
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px); }
  25% {
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px); }
  50% {
    -webkit-transform: translate(10px, -10px);
            transform: translate(10px, -10px); }
  75% {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px); }
  100% {
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px); } }

@-webkit-keyframes shadowSpin {
  0% {
    box-shadow: 10px -10px #39CCCC, -10px 10px #FFDC00;
    opacity: 0; }
  25% {
    box-shadow: 10px 10px #39CCCC, -10px -10px #FFDC00; }
  50% {
    box-shadow: -10px 10px #39CCCC, 10px -10px #FFDC00;
    opacity: 1; }
  75% {
    box-shadow: -10px -10px #39CCCC, 10px 10px #FFDC00; }
  100% {
    box-shadow: 10px -10px #39CCCC, -10px 10px #FFDC00;
    opacity: 0; } }

@keyframes shadowSpin {
  0% {
    box-shadow: 10px -10px #39CCCC, -10px 10px #FFDC00;
    opacity: 0; }
  25% {
    box-shadow: 10px 10px #39CCCC, -10px -10px #FFDC00; }
  50% {
    box-shadow: -10px 10px #39CCCC, 10px -10px #FFDC00;
    opacity: 1; }
  75% {
    box-shadow: -10px -10px #39CCCC, 10px 10px #FFDC00; }
  100% {
    box-shadow: 10px -10px #39CCCC, -10px 10px #FFDC00;
    opacity: 0; } }

.load {
  z-index: -1;
  background-color: #CC3333;
  opacity: 0.75;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  margin: -5px auto 0 auto;
  left: 0;
  right: 0;
  border-radius: 0px;
  border: 5px solid #FF4136;
  box-shadow: 10px 0px #39CCCC, 10px 0px #01FF70;
  -webkit-animation: shadowSpin 2s ease-in-out infinite;
          animation: shadowSpin 2s ease-in-out infinite; }
